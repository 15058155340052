.questionsAndOptionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    /* height: 90vw; */
    z-index: 2000;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 85vw;
    height: 23vw;
}

.question {
    letter-spacing: 3px;
    line-height: 1;
    font-family: Eraser;
    color: white;
    font-size: 2.5vw;
    padding: 0px;

}

.optionCompliance {
    letter-spacing: 3px;

    font-family: Montserrat;
    color: white;
    font-size: 2.2vw;
    margin-top: 20px;
    cursor: pointer;
    text-align: center;
    line-height: 1;
}

.optionFriends {
    letter-spacing: 3px;

    line-height: 1;
    text-align: center;
    text-align: center;
    font-family: Montserrat;
    color: white;
    font-size: 2.2vw;
    cursor: pointer;
}

.gameContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-self: center;
    justify-self: center
}

.timerContainer {
    position: absolute;
    justify-content: flex-end;
    right: 0.8vw;
    top: 2.9vw;
}

.counter {
    background-image: url('../../assets/images/counter.png');
    background-size: 100vw;
    width: 10vw;
    height: 10vw;
    background-position: center;
    background-repeat: no-repeat;
    font-family: Eraser;
    color: white;
    background-size: contain;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: flex-start;
}

.timer {
    color: white;
    font-family: Eraser;
    font-size: 1.8vw;
}


.optionButton {
    align-self: center;
    transition: all .2s ease-in;
}

.optionButton:hover {
    transform: translateX(5px) scale(1.08);
}

.optionButtonClick {
    transition: all .3s ease-in-out;

    width: 100%;
    margin-top: -15px;
    transform: scale(1.1);
}


.bump {
    animation: bump 300ms ease-out;
}

@keyframes bump {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(0.9);
    }

    40% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(0.9);
    }

    70% {
        transform: scale(1.1);
    }

    90% {
        transform: scale(1.2);
    }

    90% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@media (max-width:900px) and (min-width:700px) {
    .optionsContainer {
        height: 50vw;
    }

    .question {
        margin-bottom: 14vw;
        font-size: 4vw;
    }

    .optionCompliance {
        font-size: 4.2vw;
    }

    .optionButton {
        margin-top: 110px;
    }

    .optionFriends {

        font-size: 4.2vw;
    }
}

@media (max-width:700px) {
    .question {
        margin-bottom: 50px;
    }

    .optionButton {
        margin-top: 8vw;
    }

    .optionsContainer {
        height: 80vw;
    }

    .optionCompliance {
        font-size: 4.5vw;
    }

    .optionFriends {
        font-size: 5.5vw;
    }

    .questionsAndOptionsContainer {}

    .timer {
        color: white;
        font-family: Eraser;
        font-size: 2.9vw;
    }

    .timerContainer {
        position: absolute;
        justify-content: flex-end;
        right: 10vw;
        top: 23vw;
    }


    .question {
        margin-bottom: 20px;
        font-family: Eraser;
        color: white;
        font-size: 5.4vw;
    }



    .counter {
        width: 16vw;
        height: 16vw;
    }

    .gameContainer {
        display: flex;
        height: 100vw;
        /* flex-direction: column; */
        /* width: 90%; */
        align-self: center;
        justify-self: center;
    }


}