.right {
    background-image: url(/src/assets/images/right.png);
    width: 30px !important;
    height: 30px !important;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    transition: all;
    animation: bump 300ms ease-in;
}

.wrong {
    background-image: url(/src/assets/images/wrong.png);
    width: 30px !important;
    height: 30px !important;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-left: -8px;
    transition: all;
    animation: bump 300ms ease-in;

}
.bump {
    animation: bump 300ms ease-in;
}
@keyframes bump {
    0% {
        transform: scale(0.5)
    }
    10% {
        transform: scale(0.7)
    }
    20% {
        transform: scale(0.8)
    }
    30% {
        transform: scale(0.9)
    }
    40% {
        transform: scale(1)
    }
    50% {
        transform: scale(1) 
    }
    60% {
        transform: scale(1.1)
    }
    70% {
        transform: scale(1.2) 
    }
    80% {
        transform: scale(1.3)
    }
    90% {
        transform: scale(1.2) 
    }
    100% {
        transform: scale(1) 
    }
}

@media (max-width:700px) {
    .right {
        width: 6vw;
        height: 6vw;
    }

    .wrong {
        width: 6vw;
        height: 6vw;
    }
}