.finishedContainer {
    width: 100vw;
    height: 100vh;
    background-image: url(../../assets/plainBg.png);
    /* background-position: ; */
    background-size: auto;
    background-repeat: no-repeat;
    /* overflow: hidden; */
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0vw;
    left: -5vw;
}

.finishedMessage {
    margin-top: -50px;
    /* background-color: red; */
    position: relative;
    top: 5vh;
    /* left: 35%; */
    width: 500px;
    height: 500px;
    background-image: url(../../assets/message.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.closeButton {
    position: absolute;
    cursor: pointer;
    width: 65px;
    height: 65px;
    top: 65px;
    right: 38px;
    /* background-color: rgba(255, 255, 255, 0.4); */
    border-radius: 400vh;
}



.rowContainer {
    left: -8vw;
    top: 35vh;
    position: absolute;
    width: 100vw;
    display: flex;
    /* height: 100%; */
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;

}