.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mainLogo {
  position: absolute;
  /* background-image: url(assets/images/amgen.png); */
  width: 20vw;
  height: 20vw;
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
  padding: 0px;
  margin-top: -5vw;
  top: 0%;
  justify-content: center;
  z-index: 2000;
}

.deck {
  position: absolute;
  width: 100vw;
  background-size: contain;
  height: 20vh;
  padding-top: 20vw;
  margin-top: 20vw;
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  bottom: 0;
}

.board {
  display: flex;
  width: 90vw;
  height: 100vh;
  position: relative;
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
  padding: 0px;
  justify-content: center;
}

.landscape {
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 3000;
  background-image: url(assets/images/bg.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .landscape {
    display: flex;
  }
}

@media (max-width:700px) {
  .mainLogo {
    width: 220px;
    height: 220px;
    top: 0
  }

  .board {
    /* background-image: url(assets/images/board2.png); */
    display: flex;
    width: 100vw;
    height: 99vh;
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0px;
    justify-content: center;
  }
}

.text {
  font-family: Eraser;
  color: white;
  font-size: 4vw;
}

.background-image {
  justify-content: center;
  align-items: center;
  display: flex;
  background-image: url(assets/images/bg.png);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}